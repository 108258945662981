body {
  background: #000;
  margin: 0;
}

body h1 {
  padding: 10px;
}

h1, h2, p, ul, li {
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", 
  Helvetica, Arial, "Lucida Grande", sans-serif;
  text-decoration: none;
  text-transform: uppercase;
  color: #eee;
}

.header {
	position: fixed;
	left: 0;
	top: 120px;
}

.subNav {
	position: fixed;
	left: 200;
	top: 120px;
	display: block;
}

.subNav li a {
  color: rgb(169,0,0);
  font-weight: bold;
  text-decoration: none;
}

ul.header li {
  display: block;
  list-style-type: none;
  margin: 0;
}

ul.header li a, h1 a {
  color: #EEE;
  font-weight: bold;
  text-decoration: none;
  display: inline-block;
}

h1 a {
  color: #fff;
  font-size: .7em;
}

h1 a:hover {
  text-decoration: none;
  color:#eee;
}

.content{
   width: 80%;
   margin: auto;
}

.carousel{
	top: 160px;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  height: 100px;
  width: 100px;
  outline: black;
  margin-top: 80%;
  background-size: 100%, 100%;
  background-image: none;
}

.carousel-control-next-icon:after
{
  content: '>';
  font-size: 25px;
  margin-right: -250%;
  color: white;
}

.carousel-control-prev-icon:after {
  content: '<';
  font-size: 25px;
  margin-left: -250%;
  color: white;
}

.content div img {
  margin: 0 auto;
  width: 75%;
}

.carousel-indicators {
  display: none;
}



.content li {
  list-style-type: none;
}

.content {
  text-align: center;
}
.content h2 {
  padding: 0;
  margin: 0;
}

.sub-content {
  padding: 20px;
  display: inline;
}
.sub-content h2 {
  padding: 0;
  margin: 0;
}

img {
  height: 100%;
  width: 100%;
}

.active {
  color: #fff !important;
}

/*HOME PAGE*/
.hometrack, .hometext, .homephoto {
	padding: 169px 0;
  margin: auto;
}

.hometext {
	width: 50%;
	text-align: center;
	right: 0;
	font-size: .8em;
}

.gallery img, gallery iframe {
  padding-bottom: 80px;
}

.writing {
  width: 50%;
  margin: auto;
  padding: 90px 0;
}

.hometrack {
  width: 50%;
}

.gallery iframe {
  margin-top: 190px;
  display: block;
  padding-bottom: 40px;
  border: none;
  margin: auto;
}

.gallery-gif img{
  max-width:800px;
  padding: 30px;
}

#swa-page{
    padding-top: 300px;
    margin-left: auto;
    margin-right: auto;
    width: 28em;
}

/*MOBILE SHIT*/
@media only screen and (max-width: 600px) {
  .subNav {
    left: 37%;
  }

  .gallery {
    margin-top: 170px;
  }


}
